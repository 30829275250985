import { createChart } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react'
import { getChartData } from '../../helper/CommFun';
import { useDispatch, useSelector } from 'react-redux';
import { connectWebSocket, disconnectWebSocket } from '../../redux/websocket/websocketActions';
import { useDataContext } from '../../providers/chartSharedContext';
import { useMarkerDataContext } from '../../providers/chartMarkerContext';

const Chart = () => {
    const dispatch = useDispatch();
    const chartContainerRef = useRef();
    const [lineSeries, setLineSeries] = useState(null);
    const [lastPrice, setLastPrice] = useState(null);
    const { connected, messages } = useSelector((state) => state.websocket);
    // const connected = useSelector((state) => state.websocket.connected);
    const { markerdata, setMarkerData } = useMarkerDataContext();
    const { data } = useDataContext();
    const [customMarkers, setCustomMarkers] = useState([]); // State to hold markers

    useEffect(() => {
        addMarker();
    }, [markerdata]);

    useEffect(() => {
        if(lineSeries){
            lineSeries.setData([]);
            lineSeries.setMarkers([]);
        }
        setCustomMarkers([]);
    }, [data]);

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                // backgroundColor: '#f0f4c3',
                background: {
                    type: 'solid', // Define the background type as solid
                    color: 'transparent', // Set the background color to light yellow-green
                },
                textColor: '#ffffff',
            },
            grid: {
                vertLines: {
                    color: 'rgba(128, 128, 128, 0.2)',
                },
                horzLines: {
                    color: 'rgba(128, 128, 128, 0.2)',
                },
            },
            rightPriceScale: {
                visible: true, // Show price on the right side
            },
            timeScale: {
                timeVisible: true, // Display time at the bottom of the chart
                borderColor: '#d1d4dc', // Customize the border color for the time scale
            },
        });

        const series = chart.addAreaSeries({
            topColor: 'rgba(66, 88, 100, 0.56)',
            bottomColor: 'rgba(66, 88, 100, 0.04)',
            lineColor: 'rgba(66, 88, 100, 1)',
            lineWidth: 4,
        });

        setLineSeries(series);

        const handleResize = () => {
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            chart.remove();
            window.removeEventListener('resize', handleResize);
        };  // Cleanup on unmount
    }, []);

    useEffect(() => {
        if (connected && messages.length > 0) {
            fetchSymbolData();
        }
    }, [messages]);

    // Function to fetch symbol data
    const fetchSymbolData = async () => {
        try {
            messages.map((element) => {
                if (element.includes(data)) {
                    const newLastPrice = Number(element[2]);
                    const time = new Date(element[9].replace(" T", " "));
                    // const finalTime = time.getTime() - time.getTimezoneOffset() * 60000;
                    const finalTime = Math.floor(time.getTime() / 1000);

                    setLastPrice(newLastPrice); // Update state with the new price

                    const newPoint = {
                        time: finalTime,// Use current timestamp
                        value: newLastPrice, // Use 'ltp' as the value (last traded price)
                    };
                    // Update the chart with the new data                    
                    if (lineSeries) {
                        lineSeries.update(newPoint); // Update the chart
                    }

                }
            })

        } catch (error) {
            console.error('Error fetching symbol data:', error);
        }
    };
    // let markers = [];
    const addMarker = (price) => {
        if (markerdata && lineSeries) {

            const currentDateTime = new Date();
            let newMarker;
            if (markerdata.actionType == "CALL") {
                newMarker = {
                    time: Math.floor(currentDateTime.getTime() / 1000), // Current time
                    position: 'aboveBar', // Position of the marker
                    color: 'green', // Color for buy
                    shape: 'arrowUp', // Shape of the marker
                    text: `Buy: ${markerdata.price}`, // Display current price
                };
            } else {
                newMarker = {
                    time: Math.floor(currentDateTime.getTime() / 1000), // Current time
                    position: 'belowBar', // Position of the marker
                    color: 'red', // Color for buy
                    shape: 'arrowDown', // Shape of the marker
                    text: `sell: ${markerdata.price}`, // Display current price
                };
            }

            // console.log(markers);
            customMarkers.push(newMarker); // Add the new marker to the markers array
            lineSeries.setMarkers(customMarkers); // Update the markers on the series

        }
    };

    return (
        <div className="charts">
            <div ref={chartContainerRef} id="chart" className="chart-background" style={{ width: '100%', height: 'calc(100vh - 110px)' }} />
        </div>
    )
}

export default Chart