import React, { useState } from "react";
import store from "../../redux/store/store";
import { loginUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { dispatch } = store;
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(loginUser(formData))
      .unwrap()
      .then((res) => {
        navigate("/dashboard");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleFieldChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  return (
    <>
      <section className="vh-100" style={{ backgroundColor: '#000000' }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card shadow-2-strong" style={{ borderRadius: '1rem' }}>
                <div className="card-body p-5 text-center">
                  <h3 className="mb-5">Sign in</h3>
                  <form onSubmit={handleSubmit}>

                    <div data-mdb-input-init className="form-outline mb-4">
                      <input type="text" id="typeEmailX-2" className="form-control form-control-lg" placeholder="Client ID" name="ClientID" onChange={handleFieldChange} required/>
                      {/* <label className="form-label" for="typeEmailX-2">Client ID</label> */}
                    </div>

                    <div data-mdb-input-init className="form-outline mb-4">
                      <input type="password" id="typePasswordX-2" className="form-control form-control-lg" placeholder="Password" name="pwd" onChange={handleFieldChange} required/>
                      {/* <label className="form-label" for="typePasswordX-2">Password</label> */}
                    </div>

                    <button data-mdb-button-init data-mdb-ripple-init className="btn btn-primary btn-lg btn-block" type="submit">Login</button>

                    <hr className="my-4" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
