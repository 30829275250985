// src/components/layouts/MainLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { Footer } from "./Footer";
const MainLayout = () => {
  return (
      <section id="main-box">
        <div className="container-box">
          <Header />
          <section id="homeBanner" className="border-bttm">
            <div className="homeBanner-part">
              <div className="container-fluid p-0">
                <div className="homeBanner-box">
                  <Sidebar />
                  {/* <div className="main-contant">
                        <div className="container-fluid"> */}
                  <Outlet /> {/* Nested routes will render here */}
                  {/* </div>
                      </div> */}
                  {/* <Footer /> */}
                </div>
              </div>
            </div>
          </section>


        </div>
      </section >
  );
};

export default MainLayout;
