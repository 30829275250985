// websocketActions.js
export const connectWebSocket = () => ({
    type: 'WS_CONNECT',
});

export const disconnectWebSocket = () => ({
    type: 'WS_DISCONNECT',
});

export const sendMessage = (message) => ({
    type: 'WS_SEND_MESSAGE',
    payload: message,
});
