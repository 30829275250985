import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS_TYPE } from "../../constants/STATUS_TYPE";
import { API_URL } from "../../constants/API_URL";
import { ToastFire, isEmpty } from "../../helper/CommFun";
import { _post } from "../../utils/api/apiClient.jsx";

export const loginUser = createAsyncThunk(
  "auth/loginUser", // Use a specific action type
  async (userCredentials, { rejectWithValue, dispatch }) => {
    try {
      const response = await _post(API_URL.AUTH_LOGIN, userCredentials);

      if (response.status == 200) {
        if(response.data.d !=='[]'){
          ToastFire("success", "Login Successfully");
        }else{
          ToastFire("error", "Login Failed!!");  
        }
        return response.data; // Assuming your API returns user data
      } else {
        ToastFire("error", response.statusText);
        return rejectWithValue(response.statusText); // Adjust according to your error structure
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); // Adjust according to your error structure
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: null,
    isAuthenticated: false,
    token: null,
    status: STATUS_TYPE.LOADING,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.userData = null;
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
        state.isAuthenticated = false; // Adjust according to your response structure
        state.error = null; // Clear previous errors
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.userData = JSON.parse(action.payload.d)[0]; // Adjust according to your response structure
        // state.token = action.payload.data.token; // Adjust according to your response structure
        state.isAuthenticated = true; // Adjust according to your response structure
        // localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("user_data", state.userData.User_Code);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.isAuthenticated = false; // Adjust according to your response structure
        state.error = action.payload || {
          message: "An unknown error occurred.",
        }; // Handle error more clearly
      });
  },
});

// Export the action to log out
export const { logout } = authSlice.actions;

// Export the reducer to be used in the store
export default authSlice.reducer;
