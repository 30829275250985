import React, { useEffect, useState } from 'react'
import countryImage from '../../assets/images/country-img.png';
import portfolioImage from '../../assets/images/portfolio.png';
import historyImage from '../../assets/images/history.png';
import Chart from '../../pages/chart/chart';
import { Button } from '../button';
import { buyTrade } from '../../redux/slices/buySlice';
import { useDispatch, useSelector } from 'react-redux';
import { daybook } from '../../redux/slices/daybookSlice';
import { useDataContext } from '../../providers/chartSharedContext';
import { format } from 'date-fns';
import { betHistory } from '../../redux/slices/betHistorySlice';
import { eventWrapper } from '@testing-library/user-event/dist/utils';
import { API_URL } from '../../constants/API_URL';
import { _post } from '../../utils/api/apiClient';
import { closeBet } from '../../redux/slices/betHistorySlice';
import { useMarkerDataContext } from '../../providers/chartMarkerContext';
import { usePriceDataContext } from '../../providers/currencyPriceContext';
import { connectWebSocket, disconnectWebSocket } from '../../redux/websocket/websocketActions';
import { fetchSocketData } from '../../helper/CommFun';


export const Sidebar = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { buydata } = useSelector((state) => state.buy);
  const { daybookdata } = useSelector((state) => state.daybookmf);
  const { betHistoryData } = useSelector((state) => state.betHistory);
  const [count, setCount] = useState(1);
  const { data } = useDataContext();
  const { markerdata, setMarkerData } = useMarkerDataContext();
  const { connected, messages } = useSelector((state) => state.websocket);
  const [socketData, setSocketData] = useState([]);
  const [time, setTime] = useState('');
  const { priceData } = usePriceDataContext();
  const [ dayBookPriceData , setDayBookPriceData ] = useState({})
  const [ messgeData , setMessageData ] = useState([]);

  useEffect(() => {
    if (connected && messages.length > 0) {
      const newData = fetchSocketData(messages).then(resultArray => {
        setMessageData(resultArray)
      });
    }
  }, [messages])
  

  const openNav = () => {
    document.getElementById("totalPortfolioPopup").style.display = "block";
  
    const tableElement = document.querySelector("table");
  };

  const closeNav = () => {
    document.getElementById("totalPortfolioPopup").style.display = "none";

  };

  const openHistory = () => {
    document.getElementById("trandingHistoryData").style.display = "block";

    document.getElementById("chart").style.height = "calc( 100vh - 360px )";

    document.querySelector("table").style.height = "calc( 100vh - 360px )";
  };

  const closeHistory = () => {
    document.getElementById("trandingHistoryData").style.display = "none";
    document.getElementById("chart").style.height = "calc( 100vh - 110px )";
    document.querySelector("table").style.height = "calc( 100vh - 110px )";
  };

  //old code comment 
  // const handlebuy = (event) => {
  //   try {
  //     const actionType = event == 1 ? "CALL" : "PUT";
  //     let newLastPrice ,formattedDateTime, finalTime;
  //     messages.map((element) => {
  //       if (element.includes(data)) {
  //         newLastPrice = Number(element[2]);
  //         const time = new Date(element[9].replace(" T", " "));
  //         // finalTime = time.getTime() - time.getTimezoneOffset() * 60000;
  //         const finalTime = Math.floor(time.getTime() / 1000);
  //         formattedDateTime = format(new Date(finalTime), 'yyyy/MM/dd HH:mm:ss');
  //         const newPoint = {
  //           time: formattedDateTime,
  //           value: newLastPrice,
  //         };
  //         // setSocketData(newPoint);
  //         return true;
  //       }else{
  //         const currentDateTime = new Date();
  //         const time =  Math.floor(currentDateTime.getTime() / 1000);
  //         formattedDateTime = format(new Date(time), 'yyyy/MM/dd HH:mm:ss');
  //         messgeData.map((item)=>{
  //           if(item[0] == data){
  //             newLastPrice = item[1];
  //           }
  //         })
  //       }
  //     })
  //     const body = {
  //       cmbexchange: "MCX",
  //       cmbsymbol: data,
  //       AS_UserCode: userData.User_Code,
  //       Buy_Or_Sell: actionType,
  //       txtqty: count,
  //       txtprice: newLastPrice,
  //       undercreateduser: userData.User_Created_Under,
  //       ordertype: "LIMIT",
  //       cmbvalidity: formattedDateTime,
  //       symbol1: data,
  //       STRIKE: newLastPrice,
  //       CLOSING: actionType
  //     };
  //     if (count != 0) {
  //       dispatch(buyTrade(body))
  //       .unwrap()
  //       .then((res) => {
  //         const mData ={
  //           price : newLastPrice,
  //           actionType : actionType
  //         }
  //         setMarkerData(mData);
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  //updated code
  const handlebuy = (event) => {
    try {
      let newLastPrice, formattedDateTime;
      const currentDateTime = new Date();
      
      // Search for the matching message or use fallback price data
      const foundMessage = messages.find((element) => element.includes(data));
  
      if (foundMessage) {
        newLastPrice = Number(foundMessage[2]);
        const time = new Date(foundMessage[9].replace(" T", " "));
        const finalTime = Math.floor(time.getTime() / 1000); // Convert to seconds
        formattedDateTime = format(new Date(finalTime), 'yyyy/MM/dd HH:mm:ss');
      } else {
        const time = Math.floor(currentDateTime.getTime() / 1000); // Current time in seconds
        formattedDateTime = format(new Date(time), 'yyyy/MM/dd HH:mm:ss');
        
        // Find the price data for the given currency
        const foundPriceData = priceData.find((item) => item['Currency'] === data);
        if (foundPriceData) {
          newLastPrice = foundPriceData['price'];
        }
      }
  
      const body = {
        cmbexchange: "MCX",
        cmbsymbol: data,
        AS_UserCode: userData.User_Code,
        Buy_Or_Sell: event,
        txtqty: count,
        txtprice: newLastPrice,
        undercreateduser: userData.User_Created_Under,
        ordertype: "LIMIT",
        cmbvalidity: formattedDateTime,
        symbol1: data,
        STRIKE: newLastPrice,
        CLOSING: event
      };
  
      if (count !== 0) {
        dispatch(buyTrade(body))
          .unwrap()
          .then((res) => {
            const mData = {
              price: newLastPrice,
              actionType: event
            };
            console.log(body);
            setMarkerData(mData);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseBet = async (item) =>{
    const bodyData = {
      ID:item.ID
    }
    dispatch(closeBet(bodyData));
  }

  const getUpdatedTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 1); // Add 1 minute
    return format(now, 'HH:mm'); // Format the time as desired
  };

  useEffect(() => {
    setTime(getUpdatedTime());
    const timer = setInterval(() => {
      setTime(getUpdatedTime());
    }, 60000); 
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if(userData){
      const bodydata = {
        ClientCode: userData.User_Code,
        undercreated: userData.User_Created_Under
      }
      dispatch(daybook(bodydata));
      dispatch(betHistory(bodydata));
    }
  }, [dispatch])

  const increment = () => {
    setCount(count + 5);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 5);
    }
  };
  
  const convertToDateTime = (dateString, type) => {
    const date = new Date(dateString);

    // Get time in HH:MM format
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    // Get date in DD MMM format
    const options = { day: 'numeric', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return type == 1 ? time : formattedDate;
  };

  return (
    <div className="portfolio-box">

      <div className="left-sidebar" id='leftSidebar'>
       

        <button className="openbtn" onClick={openNav}>
          {/* <img src={portfolioImage} alt="" />  */}
          <svg
            width={50}
            height={50}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
        <g clipPath="url(#clip0_52_5086)">
          <path
            d="M45.8333 10.4167H35.4167V8.33329C35.4167 6.03535 33.548 4.1666 31.25 4.1666H18.75C16.4521 4.1666 14.5833 6.03525 14.5833 8.33329V10.4166H4.1667C1.86865 10.4167 0 12.2853 0 14.5833V20.8333C0 23.1313 1.86865 25 4.1667 25H20.8334V23.9583C20.8334 23.3825 21.2993 22.9166 21.8751 22.9166H28.1251C28.7009 22.9166 29.1668 23.3825 29.1668 23.9583V25H45.8335C48.1313 25 50 23.1313 50 20.8333V14.5833C50 12.2853 48.1313 10.4167 45.8333 10.4167ZM31.25 10.4167H18.75V8.33329H31.25V10.4167Z"
            fill="#425864"
          />
          <path
            d="M49.4222 26.124C49.0672 25.948 48.643 25.9888 48.3296 26.2268C47.5891 26.7873 46.7264 27.0833 45.8332 27.0833H29.1667V30.2083C29.1667 30.7841 28.7008 31.25 28.125 31.25H21.875C21.2992 31.25 20.8333 30.7841 20.8333 30.2083V27.0833H4.1667C3.27354 27.0833 2.41094 26.7873 1.67031 26.2268C1.35596 25.9867 0.932812 25.946 0.577734 26.124C0.223828 26.3001 0 26.6611 0 27.0569V41.6667C0 43.9646 1.86865 45.8334 4.1667 45.8334H45.8334C48.1313 45.8334 50.0001 43.9647 50.0001 41.6667V27.0569C50 26.6611 49.7762 26.3001 49.4222 26.124Z"
            fill="#425864"
          />
        </g>
        <defs>
          <clipPath id="clip0_52_5086">
            <rect width={50} height={50} fill="white" />
          </clipPath>
        </defs>
        </svg>
        <span>Total Portfolio </span>
        </button>
       
        <button className="history" onClick={openHistory}>
        <svg
          width={50}
          height={50}
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <g clipPath="url(#clip0_53_5110)">
          <path
            d="M14.4542 25.1591C13.4507 24.8648 12.4094 24.7193 11.3636 24.7273C8.50178 24.7272 5.74526 25.8069 3.6449 27.7508C1.54453 29.6947 0.254993 32.3595 0.0339621 35.2129C-0.187069 38.0662 0.676682 40.8978 2.45262 43.142C4.22856 45.3861 6.7859 46.8775 9.61363 47.3182C10.1925 47.4096 10.7776 47.4552 11.3636 47.4545C14.107 47.455 16.7579 46.4629 18.8269 44.6613C20.8958 42.8597 22.243 40.3703 22.6197 37.6529C22.9964 34.9355 22.3771 32.1736 20.8762 29.8771C19.3754 27.5807 17.0943 25.9049 14.4542 25.1591ZM14.1591 41.6591C13.8676 41.7984 13.5504 41.8758 13.2275 41.8864C12.8433 41.8826 12.4678 41.7723 12.1427 41.5676C11.8176 41.363 11.5558 41.072 11.3864 40.7273L9.52257 37.0227C9.37056 36.7358 9.29256 36.4156 9.29566 36.0909V32.3636C9.29566 31.8151 9.51356 31.289 9.90144 30.9012C10.2893 30.5133 10.8154 30.2954 11.3639 30.2954C11.9125 30.2954 12.4385 30.5133 12.8264 30.9012C13.2143 31.289 13.4322 31.8151 13.4322 32.3636V35.6136L15.0679 38.8864C15.3128 39.375 15.3547 39.9405 15.1845 40.4599C15.0143 40.9792 14.6457 41.4103 14.1591 41.6591Z"
            fill="#425864"
          />
          <path
            d="M43.1819 2H15.9091C14.1025 2.00532 12.3713 2.72537 11.0938 4.00287C9.81631 5.28038 9.09626 7.01152 9.09094 8.81818V22.6591C9.84065 22.5214 10.6014 22.4529 11.3637 22.4545C14.2941 22.4532 17.1469 23.3962 19.4991 25.1439C21.8512 26.8916 23.5775 29.3509 24.4219 32.157C25.2663 34.9631 25.1839 37.9666 24.1868 40.7222C23.1897 43.4777 21.3311 45.8385 18.8865 47.4545H43.1819C44.9885 47.4492 46.7197 46.7292 47.9972 45.4517C49.2747 44.1742 49.9947 42.443 50 40.6364V8.81818C49.9947 7.01152 49.2747 5.28038 47.9972 4.00287C46.7197 2.72537 44.9885 2.00532 43.1819 2ZM40.9091 33.8182H32.9546C32.3518 33.8182 31.7737 33.5787 31.3475 33.1525C30.9213 32.7263 30.6819 32.1482 30.6819 31.5455C30.6819 30.9427 30.9213 30.3646 31.3475 29.9384C31.7737 29.5122 32.3518 29.2727 32.9546 29.2727H40.9091C41.5119 29.2727 42.09 29.5122 42.5162 29.9384C42.9424 30.3646 43.1819 30.9427 43.1819 31.5455C43.1819 32.1482 42.9424 32.7263 42.5162 33.1525C42.09 33.5787 41.5119 33.8182 40.9091 33.8182ZM40.9091 25.8636H27.2728C26.67 25.8636 26.0919 25.6242 25.6657 25.198C25.2395 24.7717 25 24.1937 25 23.5909C25 22.9881 25.2395 22.4101 25.6657 21.9838C26.0919 21.5576 26.67 21.3182 27.2728 21.3182H40.9091C41.5119 21.3182 42.09 21.5576 42.5162 21.9838C42.9424 22.4101 43.1819 22.9881 43.1819 23.5909C43.1819 24.1937 42.9424 24.7717 42.5162 25.198C42.09 25.6242 41.5119 25.8636 40.9091 25.8636ZM40.9091 17.9091H18.1819C17.5791 17.9091 17.001 17.6696 16.5748 17.2434C16.1486 16.8172 15.9091 16.2391 15.9091 15.6364C15.9091 15.0336 16.1486 14.4555 16.5748 14.0293C17.001 13.6031 17.5791 13.3636 18.1819 13.3636H40.9091C41.5119 13.3636 42.09 13.6031 42.5162 14.0293C42.9424 14.4555 43.1819 15.0336 43.1819 15.6364C43.1819 16.2391 42.9424 16.8172 42.5162 17.2434C42.09 17.6696 41.5119 17.9091 40.9091 17.9091Z"
            fill="#425864"
          />
        </g>
      <defs>
        <clipPath id="clip0_53_5110">
          <rect width={50} height={50} fill="white" />
        </clipPath>
      </defs>
      </svg>
      <span>Trading History </span>
        </button>
      </div>

      <div className="main-chart" id="mainchart">
        <div id="totalPortfolioPopup" className="totalPortfolioPopup">
            <div className="sidebar-header">
              <div className="d-flex justify-content-between align-items-center">
                <p>Total Portfolio</p>
                <a className="closebtn" onClick={closeNav}>×</a>
              </div>

          <div className="dropdown">
            <button className="dropdown-btn">
              All Positions
              <i className="fa fa-caret-down"></i>
            </button>
            </div>
          </div>

          <div className="portfolio" id="scrollbar">
            {betHistoryData.map((item, index) => (
              <div key={index} className="stocks one">
                <div className="time">
                  <span>{convertToDateTime(item.BETDATE, 1)}</span>
                  <p>{convertToDateTime(item.BETDATE, 0)}</p>
                </div>
                <div className="country-stock">
                  <img src={item.IMAGEURL || countryImage} alt="" />
                  <span>{item?.SYMBOL}</span>
                </div>
                <div className="profit">
                  {/* <span>{item.P_L || '0.00'}</span> */}
                    {messgeData.length > 0 ? messgeData.map((items)=>{
                        if(items[0] === item.SYMBOL){
                          const profitorloss = (items[1] - item.STRIKE) * item.AMOUNT;
                          return (<span className={profitorloss>0?'profit':'loss'}>{profitorloss?profitorloss.toFixed(6):"0:0"}</span>) ;
                        }
                    }) : ''}
                  <span className='d-block text-end' style={{ color: 'gray' }}>${item.AMOUNT.toFixed(2)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="chart_history_part">

          <div className="chart-rightsidebar">

             <Chart />
             <div className="right-sidebar">
                <div className="top-part">
                <div className="amount-sub-block amount-block-price">
                  <div className="amount-inner">
                    <h5>Amount</h5>
                    <div className="amount-inner-price">
                      <div><p><span>$</span>{count}</p></div>
                    </div>
                  </div>
                  <div className="amount-block-button">
                    <button className="btn" onClick={increment} id="plusBtn">+</button>
                    <button className="btn" onClick={decrement} id="minusBtn">-</button>
                  </div>
                </div>
                <div className="amount-sub-block amount-block-price">
                  <div className="amount-inner">
                    <h5>Expiration</h5>
                    <div className="amount-inner-price">
                      <div><p><span>12:56</span></p></div>
                    </div>
                  </div>
                  <div className="amount-block-button">
                    <button className="btn" id="plusBtn">+</button>
                    <button className="btn" id="minusBtn">-</button>
                  </div>
                </div>
                {/* <div className="profit-section">
                  <p>Profit</p>
                  <h3>+83%</h3>
                  <h5>+$830</h5>
                </div> */}
                </div>
                <Button onButtonClick={handlebuy} />
        </div>
          </div>

        <div id="trandingHistoryData" className="trandingHistoryData">
          <div className="sidebar-header d-flex justify-content-between align-items-center">
          <p>Total Portfolio</p>
          <a className="closebtn" onClick={closeHistory}>×</a>
          </div>
          <div className="portfolio">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Investment</th>
                  <th>Sale P/L</th>
                </tr>
              </thead>
              <tbody id='scrollbar'>
                {daybookdata.length > 0 && daybookdata.map((dayData, index) => (
                  <tr key={index}>
                    <td><img src={countryImage} alt="" />{dayData.SYMBOL}</td>
                    <td>${dayData.AMOUNT}</td>
                    <td>
                      {messgeData.length > 0 ? messgeData.map((items)=>{
                          if(items[0] === dayData.SYMBOL){
                            const profitorloss = (items[1] - dayData.STRIKE) * dayData.AMOUNT;
                            return (<span className={profitorloss>0?'profit':'loss'}>{profitorloss?profitorloss.toFixed(6):"0:0"}</span>) ;
                          }
                      }) : ''}
                      </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    
    </div>
  )
}
