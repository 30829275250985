import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ roles }) => {
  const { userData, token } = useSelector((state) => state.auth);
  const user_data = localStorage.getItem('user_data')
  if (!user_data) {
    return <Navigate to="/login" replace />;
  }

  if (roles && roles.length > 0) {
    if (!userData || !roles.includes(userData.role)) {
      return <Navigate to="/not-authorized" replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoute;
