import React, { createContext, useContext, useState } from 'react';

// Create the context
const MarkerDataContext = createContext();

// Create the provider component
export const MarkerDataProvider = ({ children }) => {
  const [markerdata, setMarkerData] = useState(null);
  // console.log("data2",data); 
  return (
    <MarkerDataContext.Provider value={{ markerdata, setMarkerData }}>
      {children}
    </MarkerDataContext.Provider>
  );
};

// Create the custom hook
export const useMarkerDataContext = () => {
  const context = useContext(MarkerDataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
