import React, { createContext, useContext, useState } from 'react';

// Create the context
const ChartPriceDataContext = createContext();

// Create the provider component
export const ChartPriceDataProvider = ({ children }) => {
  const [priceData, setPriceData] = useState(null);
  // console.log("data2",data); 
  return (
    <ChartPriceDataContext.Provider value={{ priceData, setPriceData }}>
      {children}
    </ChartPriceDataContext.Provider>
  );
};

// Create the custom hook
export const usePriceDataContext = () => {
  const context = useContext(ChartPriceDataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
