import { createAsyncThunk ,createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/API_URL";
import { _post } from "../../utils/api/apiClient";
import { STATUS_TYPE } from "../../constants/STATUS_TYPE";

export const betHistory = createAsyncThunk(
    'betHistory',
    async (bodyData,{rejectWithValue,dispatch}) =>{
        try {
            const response = await _post(API_URL.BETHISTORY,bodyData);
            if(response.status==200){
                return response.data;   
            }else{
                return rejectWithValue(response.statusText); 
            }
        } catch (error) {
            return rejectWithValue(error.message) // Adjust according to your error structure
        }
    }
)

export const closeBet = createAsyncThunk(
    'close',
    async (bodyData,{rejectWithValue,dispatch}) =>{
        try {
            const response = await _post(API_URL.CLOSEBET,bodyData);
            if(response.status==200){
                return response.data;   
            }else{
                return rejectWithValue(response.statusText); 
            }
        } catch (error) {
            return rejectWithValue(error.message) // Adjust according to your error structure
        }
    }
)




const betSlice = createSlice({
    name: "betHistory",
    initialState: {
        betHistoryData: [],
        status: STATUS_TYPE.IDLE,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(betHistory.pending, (state) => {
            state.status = STATUS_TYPE.LOADING;
          })
          .addCase(betHistory.fulfilled, (state, action) => {
            state.status = STATUS_TYPE.SUCCEEDED;
            state.betHistoryData = JSON.parse(action.payload.d);
            // state.currencyData = action.payload; // Set the fetched data to state
          })
          .addCase(betHistory.rejected, (state, action) => {
            state.status = STATUS_TYPE.FAILED;
            state.error = action.error.message;
          });
      },
})

export default betSlice.reducer;