import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../slices/authSlice";
import balanceReducer from "../slices/balanceSlice"
import currencyReducer from "../slices/currencySlice";
import buyReducer from "../slices/buySlice";
import daybookReducer from "../slices/daybookSlice";
import websocketMiddleware from "../websocket/websocketMiddleware";
import { websocketReducer } from "../websocket/websocketReducer";
import  betHistoryReducer from "../slices/betHistorySlice";

// Combine reducers
const appReducer = combineReducers({
  auth: authReducer,
  balance: balanceReducer,
  currency : currencyReducer,
  buy : buyReducer,
  daybookmf:daybookReducer,
  websocket: websocketReducer,
  betHistory: betHistoryReducer
});

// Persist configuration
const persistConfig = {
  key: "root",
  storage, // Storage method (localStorage)
};

// Root reducer that handles RESET_STORE action
const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined; // Reset the entire state
  }
  return appReducer(state, action);
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store with persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(websocketMiddleware),
});

// Create the persistor
export const persistor = persistStore(store);

export default store;
