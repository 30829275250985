import React from "react";
import Login from "../pages/auth/login";
import Home from "../pages/home/home";
// import Dashboard from "../pages/dashboard/Dashboard";
import Dashboard from "../pages/dashboard/Dashboard";
export const pagesData = [
  {
    path: "",
    element: <Login />,
    title: "Login",
    isPublic: true,
  },
  {
    path: "login",
    element: <Login />,
    title: "Login",
    isPublic: true,
  },
  {
    path: "home",
    element: <Home />,
    title: "Home",
    isPublic: true,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    title: "dashboard",
    // isPublic: true,
  },
];
