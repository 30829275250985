import { createAsyncThunk ,createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/API_URL";
import { _post } from "../../utils/api/apiClient";
import { STATUS_TYPE } from "../../constants/STATUS_TYPE";
import { ToastFire } from "../../helper/CommFun";

export const buyTrade = createAsyncThunk(
    'buy',
    async (bodyData,{rejectWithValue,dispatch}) =>{
        try {
            console.log(bodyData);
            const response = await _post(API_URL.BUY,bodyData);
            if(response.status==200){
                ToastFire("success", "Trade Successful");
                return response.data;   
            }else{
                ToastFire("Error", response.statusText);
                return rejectWithValue(response.statusText); 
            }
        } catch (error) {
            ToastFire("Error", "An error occurred!");
            return rejectWithValue(error.message) // Adjust according to your error structure
        }
    }
)

const buySlice = createSlice({
        name: "buy",
        initialState: {
        buydata: [],
        status: STATUS_TYPE.IDLE,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(buyTrade.pending, (state) => {
            state.status = STATUS_TYPE.LOADING;
          })
          .addCase(buyTrade.fulfilled, (state, action) => {
            state.status = STATUS_TYPE.SUCCEEDED;
            console.log(action.payload);
            state.buydata = JSON.parse(action.payload.d)[0];
            // state.buyPriceMarker = state.buydata.price;  
            // state.currencyData = action.payload; // Set the fetched data to state
          })
          .addCase(buyTrade.rejected, (state, action) => {
            state.status = STATUS_TYPE.FAILED;
            state.error = action.error.message;
          });
      },
})

export default buySlice.reducer;