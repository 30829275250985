import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBalance } from '../../redux/slices/balanceSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const { balance } = useSelector((state) => state.balance);

  const getBalance = async () => {
    try {
      const body = {
        ClientCode: userData.User_Code,
      };

      dispatch(fetchBalance(body));

    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <></>
  )
}

export default Dashboard