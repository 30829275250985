import dayjs from "dayjs";
import Swal from "sweetalert2";

//empty check
export function isEmpty(value) {
  return (
    value === null ||
    value === undefined ||
    value === false ||
    value === 0 ||
    (typeof value === "string" && !value.trim())
  );
}
export function validateStr(str) {
  return str === null ||
    str === undefined ||
    str == "" ||
    (typeof str === "string" && !str.trim())
    ? false
    : true;
}
export function validateNum(num, len) {
  return num === null ||
    num === undefined ||
    num == "" ||
    parseInt(num).toString().length != len
    ? false
    : true;
}

//email valid check
export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
//Date Helper function
function pad2(number) {
  return (number < 10 ? "0" : "") + number;
}
export function formatDate(date) {
  const formattedDate = `${date.getFullYear()}-${pad2(
    date.getMonth() + 1
  )}-${pad2(date.getDate())}T${pad2(date.getHours())}:${pad2(
    date.getMinutes()
  )}`;
  return formattedDate;
}

//Swal sweet alert
export function useToast() {
  return Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
}
export function ToastFire(icon, title) {
  const Toast = useToast();
  Toast.fire({
    icon: icon,
    title: title,
  });
}
export function jsonParser(str) {
  let cleanedString = str
    .replace(/^{"/, "[") // Replace { " at the beginning with [
    .replace(/"}$/, "]") // Replace "} at the end with ]
    .replace(/","/g, ","); // Replace "," with ,
  // Remove the extra backslashes
  cleanedString = cleanedString.replace(/\\/g, "");
  return JSON.parse(cleanedString);
}

export function localFormattedDate(str, format = "DD-MM-YYYY hh:mm A") {
  if (!str) return "";
  return dayjs(str.toLocaleString()).format(format);
}

export async function getChartData(symbol) {
  const response = await fetch('http://13.50.129.217/api/v1/symbol-data', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer fc56453b-b4sfsdfe6-427e-a481-43ae96554457426554',
    },
    body: JSON.stringify({ symbols: symbol }),
  });

  return await response.json();
}

export async function fetchSocketData(messages) {
  try {
      const store = messages.reduce((acc, curr) => {
          const key = curr[0]; 
          const value = curr[2]; 
          acc[key] = value;
          return acc;
      }, {});
      const resultArray = Object.entries(store);
      return resultArray;
  } catch (error) {
      console.error('Error fetching symbol data:', error);
  }
};

