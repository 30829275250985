const initialState = {
    connected: false,
    messages: null,
    error: null,
};

export const websocketReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'WS_CONNECTED':
            return { ...state, connected: true };
        case 'WS_DISCONNECTED':
            return { ...state, connected: false, messages: [] };
        case 'WS_MESSAGE_RECEIVED':
            // return { ...state, messages: [...state.messages, action.payload] };
            return { ...state, messages: action.payload };
        case 'WS_ERROR':
            return {
                ...state,
                error: {
                    ...(state.error || {}),  // Ensure error is initialized, avoid mutating null
                    eventPhase: action.payload.eventPhase,
                },
            };
        default:
            return state;
    }
};

