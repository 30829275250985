import React, { useEffect, useRef, useState } from 'react';
import countryImage from '../../assets/images/country-img.png';
import logo from '../../assets/images/logo.png';
import icon from '../../assets/images/icon.png';
import plusicon from '../../assets/images/plus.png';
import closeicon from '../../assets/images/close.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import store from '../../redux/store/store';
import { currencyList } from '../../redux/slices/currencySlice';
import { Modal } from 'react-bootstrap';
import { logout } from '../../redux/slices/authSlice';
import { useDataContext } from '../../providers/chartSharedContext';
import { connectWebSocket, disconnectWebSocket } from '../../redux/websocket/websocketActions';
import { usePriceDataContext } from '../../providers/currencyPriceContext';
import { fetchSocketData } from '../../helper/CommFun';


export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { balance } = useSelector((state) => state.balance);
  const [showDropdown, setShowDropdown] = useState(false);
  const [clickedItem, setClickedItem] = useState(null); // Track clicked item
  const { connected, messages } = useSelector((state) => state.websocket);
  const dropdownRef = useRef(null);
  const [previousPrices, setPreviousPrices] = useState({});

  const [currencyLastData, setLastPriceData] = useState([]); // Local state for currency data
  const { currencyData } = useSelector((state) => state.currency);
  const [showModal, setShowModal] = useState(false);  // Modal state
  const [selectedItems, setSelectedItems] = useState([]);  // State for storing selected elements from modal
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const { data, setData } = useDataContext(); // Make sure this is inside a component
  const maxSelection = 7; // Set the limit for the number of selected items
  const { priceData , setPriceData } = usePriceDataContext();
  const [ messgeData , setMessageData ] = useState([]);

  const currencySelectItem = (item) =>{    
    setData(item.Currency);
    setClickedItem(item.Currency);
  }

  useEffect(() => {
    // Connect to WebSocket on component mount
    dispatch(connectWebSocket());
    return () => {
      dispatch(disconnectWebSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    if (connected && messages.length > 0) {
      const newData = fetchSocketData(messages).then(resultArray => {
        setMessageData(resultArray)
      });
      const updatePrices = (dataArray) => {
        return dataArray.map((currencyItem) => {
          if (!currencyItem || !currencyItem.Currency) {
            return currencyItem; 
          }
          const filteredData = messgeData.filter(([key, value]) => key.includes(currencyItem.Currency)).map(([key, value]) => value);
          if (filteredData.length > 0) {
            const price = filteredData[0]; // Get the price from the filtered data
            setPreviousPrices((prev) => ({
              ...prev,
              [currencyItem.Currency]: price, // Update previousPrices state
            }));
            return {
              ...currencyItem,
              price: price, 
            };
          } else {
            const lastKnownPrice = previousPrices[currencyItem.Currency] || null; 
            return {
              ...currencyItem,
              price: lastKnownPrice, 
            };
          }
        });
      };
      const updatedCurrencyData = updatePrices(selectedItems);
      setSelectedItems(updatedCurrencyData);

      const updatedCurrencyData1 = updatePrices(currencyData);
      setLastPriceData(updatedCurrencyData1);
      setPriceData(updatedCurrencyData1);
    }
  }, [messages]);

  useEffect(() => {
      const newData = fetchSocketData(messages);
      
  }, [messages])
  

  useEffect(() => {
    if (currencyData && currencyData.length > 0) {
      setSelectedItems([currencyData[0]]); // Default to the first currency
      setClickedItem(currencyData[0].Currency); // Mark it as clicked
      setData(currencyData[0].Currency)
    }
  }, [currencyData]);


  const handleSelectItem = (item) => {
    
    if (!selectedItems.includes(item)) {
      if (selectedItems.length >= maxSelection) {
        return;
      }
      setSelectedItems((prevItems) => [...prevItems, item]);  // Add the selected item to the state
    }
    
    setShowModal(false);  // Close modal after selection
  };

  const handleRemoveItem = (index) => {
    const itemToRemove = selectedItems[index];
    if (itemToRemove.Currency === clickedItem) {
      setClickedItem(selectedItems[0].Currency);
      setData(selectedItems[0].Currency)
    }
    setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));  // Remove the selected item by its index
  };

  const handleLogout = async () => {
    Swal.fire({
      title: "Logout",
      text: `Are you sure you want to Logout?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        store.dispatch({ type: "RESET_STORE" });
        navigate("/login");
      }
    });
  };


  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    dispatch(currencyList())
    .unwrap()
    .then((res) => {
      setLastPriceData(JSON.parse(res.d));
    });
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <header id="header">
      <div className="header-part">
        <div className="container-fluid">
          <div className="header-box">
            <div className="left-nav">
              <div className="logo-box">
                <a><img src={logo} alt="logo" /></a>
                <p>B-Trading</p>
              </div>
              <div className="main-nav">
                {selectedItems.map((item, index) => (
                  <div key={index} className={`stocks one ${clickedItem === item.Currency ? 'selected' : ''}`}>
                    <div className="col-lg-3">
                      <img src={countryImage} alt="country-img" onClick={() => currencySelectItem(item)}/>
                    </div>
                    <div className="col-lg-9">
                      <p>{item.Currency}</p>
                      <span>{item.price ? `$${item.price}` : '$0'}</span> 
                    </div>
                    {selectedItems.length > 1?<img key={index} src={closeicon} onClick={() => handleRemoveItem(index)} alt="close-icon" className="close-icon" />:''}
                  </div>
                ))}
                <div className="icon">
                  <a href="#" onClick={handleOpenModal}>
                    <img src={plusicon} alt="plus-icon" />
                  </a>
                </div>
              </div>
            </div>

            <div className="right-nav">
              <div className="button-box">
                <a className="btn-common green" id="deposit">
                  <span>Deposit</span></a>
              </div>
              <div className="balance" ref={dropdownRef}>
                <div className="btn-group">
                  <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleDropdown}
                    style={{ color: 'white', textAlign: 'center' }}>
                    <span>${balance?.BALANCE.toFixed(2)}</span>
                  </button>
                  <ul className={`dropdown-menu ${showDropdown ? "show" : ""
                    } dropdown-menu-end`} style={{ marginTop: "49px" }}>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={handleLogout}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <i className="fa-solid fa-power-off" />
                          <p style={{ marginLeft: "2px", marginBottom: "0", color: '#000000' }}>
                            Logout
                          </p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="icon">
                <a>
                  <img src={icon} alt="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="currency-box-wrapper">
        <Modal.Body>
          <div className="main-nav">
            {currencyData && currencyData.length > 0 ? (
              currencyData.map((item, index) => (
                <div
                  key={index}
                  className="modal-item"
                  onClick={() => handleSelectItem(item)}  // When clicked, add to selected items
                  style={{ cursor: 'pointer', padding: '5px' }}
                >
                  <div key={index} className="stocks   one">
                    <div className="col-lg-3">
                      <img src={countryImage} alt="country-img" className="country-img" />
                    </div>
                    <div className="col-lg-9">
                      <p>{item.Currency}</p>
                      {currencyLastData.map((data)=>{
                        if(data['Currency'] == item.Currency)
                        {
                          return ( <span>{data['price'] ? `$${data['price']}` : '$0'}</span> );
                        }
                      })}
                      
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No currency data available</p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </header>
  )
}
