import React, { createContext, useContext, useState } from 'react';

// Create the context
const DataContext = createContext();

// Create the provider component
export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  // console.log("data2",data); 
  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

// Create the custom hook
export const useDataContext = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
