import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _get, _post } from "../../utils/api/apiClient";
import { API_URL } from "../../constants/API_URL";
import { STATUS_TYPE } from "../../constants/STATUS_TYPE";

export const currencyList = createAsyncThunk(
    'currency',
    async () => {
       try {
            const response = await _post(API_URL.CURRENCYLIST);
            if(response.status==200){
                return response.data;
            }else{
                return response.statusText; 
            }
       } catch (error) {
            return error.message // Adjust according to your error structure
       }
    }
);

const currencySlice = createSlice({
    name: "currency",
    initialState: {
        currencyData: [],
        status: STATUS_TYPE.IDLE,
        error: null,
    },
    reducers: {
      setCurrencyData: (state, action) => {
        state.currencyData = action.payload; // Update the currency data
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(currencyList.pending, (state) => {
            state.status = STATUS_TYPE.LOADING;
          })
          .addCase(currencyList.fulfilled, (state, action) => {
            state.status = STATUS_TYPE.SUCCEEDED;
            state.currencyData = JSON.parse(action.payload.d);

            // state.currencyData = action.payload; // Set the fetched data to state
          })
          .addCase(currencyList.rejected, (state, action) => {
            state.status = STATUS_TYPE.FAILED;
            state.error = action.error.message;
          });
      },
})

export const { setCurrencyData } = currencySlice.actions;
export default currencySlice.reducer;
