import React from 'react'


export const Button = ({onButtonClick}) => {
    return (
        <div className="buttons">
            <a className="btn-common glow-btn" id="heigh"  onClick={() => onButtonClick('CALL')}>
                <span>High</span>
            </a>
            <a className="btn-common glow-btn" id="low" onClick={() => onButtonClick('PUT')}>
                <span>Low</span>
            </a>
        </div>
    )
}
