import { createAsyncThunk ,createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/API_URL";
import { _post } from "../../utils/api/apiClient";
import { STATUS_TYPE } from "../../constants/STATUS_TYPE";

export const daybook = createAsyncThunk(
    'daybookmf',
    async (bodyData,{rejectWithValue,dispatch}) =>{
        try {
            const response = await _post(API_URL.DAYBOOKMF,bodyData);
            if(response.status==200){
                return response.data;   
            }else{
                return rejectWithValue(response.statusText); 
            }
        } catch (error) {
            return rejectWithValue(error.message) // Adjust according to your error structure
        }
    }
)

const daybookSlice = createSlice({
    name: "daybookmf",
    initialState: {
        daybookdata: [],
        status: STATUS_TYPE.IDLE,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(daybook.pending, (state) => {
            state.status = STATUS_TYPE.LOADING;
          })
          .addCase(daybook.fulfilled, (state, action) => {
            state.status = STATUS_TYPE.SUCCEEDED;
            state.daybookdata = JSON.parse(action.payload.d);
          })
          .addCase(daybook.rejected, (state, action) => {
            state.status = STATUS_TYPE.FAILED;
            state.error = action.error.message;
          });
      },
})

export default daybookSlice.reducer;