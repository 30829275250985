
const url = 'ws://78.46.93.146:9097';

let socket;

const websocketMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case 'WS_CONNECT':
            if (socket !== undefined) {
                socket.close();
            }

            // Create a new WebSocket connection
            socket = new WebSocket(url);

            // Dispatch actions based on WebSocket events
            socket.onopen = () => {
                console.log('WebSocket connected');
                store.dispatch({ type: 'WS_CONNECTED' });
            };

            socket.onmessage = (event) => {
                // console.log("web socket data", event.data);
                const message = JSON.parse(event.data);
                store.dispatch({ type: 'WS_MESSAGE_RECEIVED', payload: message });
            };

            socket.onclose = () => {
                console.log('WebSocket disconnected');
                store.dispatch({ type: 'WS_DISCONNECTED' });
            };

            socket.onerror = (error) => {
                console.log('WebSocket error', error);
                store.dispatch({ type: 'WS_ERROR', payload: error });
            };

            break;

        case 'WS_SEND_MESSAGE':
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(action.payload));
            }
            break;

        case 'WS_DISCONNECT':
            if (socket !== undefined) {
                socket.close();
            }
            store.dispatch({ type: 'WS_DISCONNECTED' });
            break;

        default:
            break;
    }

    return next(action);
};


export default websocketMiddleware;

