import logo from "./logo.svg";
import "./App.css";
import RouterComp from "./routes/Router";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./providers/chartSharedContext";
import { MarkerDataProvider } from "./providers/chartMarkerContext";
import { ChartPriceDataProvider } from "./providers/currencyPriceContext";

function App() {
  return (
    <ChartPriceDataProvider>
      <DataProvider>
        <MarkerDataProvider>
            <BrowserRouter>
              <RouterComp />
            </BrowserRouter>
        </MarkerDataProvider>
      </DataProvider>
    </ChartPriceDataProvider>
  );
}

export default App;
