import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/API_URL";
import { STATUS_TYPE } from "../../constants/STATUS_TYPE";
import { ToastFire } from "../../helper/CommFun";
import { _post } from "../../utils/api/apiClient";

export const fetchBalance = createAsyncThunk(
    "balance", // Use a specific action type
    async (bodyData, { rejectWithValue, dispatch }) => {
        try {
            const response = await _post(API_URL.BALANCE, bodyData);

            if (response.status == 200) {
                // ToastFire("success", "Login Successfully");
                return response.data; // Assuming your API returns user data
            } else {
                // ToastFire("error", response.statusText);
                return rejectWithValue(response.statusText); // Adjust according to your error structure
            }
        } catch (error) {
            console.log(error);
            return rejectWithValue(error.message); // Adjust according to your error structure
        }
    }
);

const balanceSlice = createSlice({
    name: "balance",
    initialState: {
        balance: null,
        status: STATUS_TYPE.IDLE,
        total: 0,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBalance.pending, (state) => {
                state.status = STATUS_TYPE.LOADING;
            })
            .addCase(fetchBalance.fulfilled, (state, action) => {
                state.status = STATUS_TYPE.SUCCEEDED;
                state.balance = JSON.parse(action.payload.d)[0];
                // state.total = action.payload.total;
            })
            .addCase(fetchBalance.rejected, (state, action) => {
                state.status = STATUS_TYPE.FAILED;
                state.error = action.error.message;
            })
    },
});

export default balanceSlice.reducer;
