import React from "react";
import { Route, Routes } from "react-router-dom";
import { pagesData } from "./pagesData";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";
import MainLayout from "../components/Layout/MainLayout";
import Login from "../pages/auth/login";

const RouterComp = () => {
  // const { userData } = useSelector((state) => state.auth);
  const pageRoutes = pagesData.map(
    ({ path, title, element, isPublic, roles }) => {
      if (isPublic) {
        return <Route key={title} path={`/${path}`} element={element} />;
      } else {
        // if (!userData) {
        //   // Optionally, return a redirect or a placeholder component
        //   return <Route key={title} path={`/${path}`} element={<Login />} />;
        // }
        // const prefixedPath = roles.includes(userData.role)
        //   ? `/${userData.role}/${path}`
        //   : `/${path}`;
        if (path == "dashboard") {
          return (
            <Route key={title}
             element={<ProtectedRoute roles={roles} />}
             >
              <Route element={<MainLayout />}>
                <Route key={title} path={`/${path}`} element={element} />
              </Route>
            </Route>
          );
        }
        return (
          <Route key={title} element={<ProtectedRoute roles={roles} />}>
            <Route element={<MainLayout />}>
              {/* <Route key={title} path={`/${prefixedPath}`} element={element} /> */}
            </Route>
          </Route>
        );
      }
    }
  );

  return <Routes>{pageRoutes}</Routes>;
};

export default RouterComp;
